import stageGearCategories from "../../../apps/catalog/data/stageGearCategories.json";
import stageGearProducts from "../../../apps/catalog/data/stageGearProducts.json";

export class StageGearStaticData {
  static isCategoriesEmpty() {
    return stageGearCategories.length === 0;
  }

  static getAllCategories() {
    return stageGearCategories || [];
  }

  static getCategoryBySlug(slug?: string) {
    return stageGearCategories.filter((category) => category.slug === slug);
  }

  static isProductsEmpty() {
    return stageGearProducts.length === 0;
  }

  static getProductByCategorySlug(slug?: string) {
    return stageGearProducts.filter(
      (product) => product.category.slug === slug
    );
  }

  static getProductBySlug(slug: string) {
    return stageGearProducts.filter((product) => product.slug === slug);
  }
}
